<template>
  <div style="margin: 10px 10px; overflow: hidden">
    <div style="display: flex; flex-direction: column; align-items: center">
      <v-autocomplete
        v-if="units"
        style="width: 100px; z-index: 500"
        :items="units"
        v-model="unitChosen"
        color="white"
        item-text="unitName"
        label="Units"
        @change="addColumn"
      ></v-autocomplete>
      <!-- <v-btn @click="dialog = true" style="margin-bottom: 20px" outlined -->
      <v-btn @click="removeColumn" style="margin-bottom: 20px" outlined
        >Remove Last</v-btn
      >
    </div>
    <hot-table
      :settings="hotSettings"
      style="width: 100vw"
      ref="myTable"
      v-if="hotSettings.data.length > 0"
    ></hot-table>
    <button @click="saveData">Save</button>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="black" dark>Confirm Delete</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Are you sure?</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined text @click="removeColumn">Yes - Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="dialog = false">No - Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
let url = process.env.VUE_APP_BASEURL;
import * as dayjs from "dayjs";
import { HotTable } from "@handsontable/vue";
import { textRenderer } from "handsontable/renderers/textRenderer";
import { ContextMenu } from "handsontable/plugins/contextMenu";
// import { DropdownMenu } from "handsontable/plugins/dropdownMenu";
import { registerAllModules } from "handsontable/registry";

registerAllModules();
export default {
  name: "Matrix",
  //   components: {
  // // DropdownMenu
  //   },
  data: function() {
    return {
      changes: [],
      dialog: false,
      units: [],
      unitChosen: "",
      hotSettings: {
        data: [],
        manualColumnMove: true,
        dropdownMenu: true,

        // dropdownMenu: ['remove_col', '---------', 'make_read_only', 'alignment'],
        //       dropdownMenu: [
        //   'remove_col',
        //   '---------',
        //   'make_read_only',
        //   '---------',
        //   'alignment'
        // ],
        // dropdownMenu: {
        //   items: {
        //     remove_col: {
        //       name: "Remove Column",
        //     },
        //     separator: DropdownMenu.SEPARATOR,
        //     option2: {
        //       name: "option2",
        //     },
        //   },
        // },

        contextMenu: {
          allowRemoveColumn: true,
          items: {
            row_above: {
              name:
                "Insert row above this one (custom name - When finished - SAVE)"
            },
            row_below: {
              name:
                "Insert row below this one (custom name - When finished - SAVE)"
            },
            separator: ContextMenu.SEPARATOR,
            remove_row: {
              name: "Remove Row - Then Save (NNB)"
            }

            // remove_col: {
            //   name: "Remove Column - Then Save (NNB)"
            // }
          }
        },
        afterChange: (changes, src) => {
          if (!this.$refs.myTable.hotInstance) return;
          const my_instance = this.$refs.myTable.hotInstance;
          if (src != "loadData") {
            changes.forEach(element => {
              var change = my_instance.getSourceData()[element[0]];
              console.log("change", change);
              console.log("my_instance2", my_instance.getSourceData());
              this.testing(my_instance.getSourceData());
            });
          }
        },
        colHeaders: [],
        fixedColumnsLeft: 1,
        fixedRowsTop: 1,
        columns: [],
        height: "auto",
        rowHeights: 23,
        licenseKey: "non-commercial-and-evaluation"
      }
    };
  },
  components: {
    HotTable
  },
  watch: {},
  mounted() {},
  async beforeMount() {
    await this.getTheData();
    this.hotSettings.columns = [];
    this.hotSettings.colHeaders.forEach((el, index) => {
      let insert = {};
      if (el === "Complete") {
        insert.class = "complete";
      }
      if (index === 0) {
        insert = {};
      } else {
        insert = {
          renderer(instance, td, row, col, prop, value) {
            const escaped = `${value}`;

            if (escaped === "Complete") {
              td.style.background = "green";
              td.style.color = "white";

              textRenderer.apply(this, arguments);
            } else if (escaped === "Ready") {
              td.style.background = "yellow";

              textRenderer.apply(this, arguments);
            } else if (escaped === "Not Done") {
              td.style.background = "red";
              td.style.color = "white";

              textRenderer.apply(this, arguments);
            } else if (escaped === "In Progress") {
              td.style.background = "blue";
              td.style.color = "white";

              textRenderer.apply(this, arguments);
            } else {
              textRenderer.apply(this, arguments);
            }

            return td;
          },
          type: "dropdown",
          source: ["Complete", "Ready", "Not Done", "In Progress", ""],
          width: 100
        };
      }
      this.hotSettings.columns.push(insert);
    });
  },
  methods: {
    testing(info) {
      this.hotSettings.data = info;
      this.saveData();
    },
    async getTheData() {
      let data = {
        id: this.$store.state.development.id
      };
      await axios({
        method: "post",
        url: `${url}/matrixfetchWB`,
        data: data
      })
        .then(
          response => {
            if (response.data[1].length) {
              this.hotSettings.colHeaders = [];
              this.hotSettings.colHeaders = JSON.parse(
                response.data[1][0].colHeaders
              );
              let allUnits = response.data[0].filter(el => {
                return el.unitName.length < 5;
              });
              let allUnitsByName = [];
              allUnits.forEach(el => {
                allUnitsByName.push(el.unitName);
              });
              let usedUnits = this.hotSettings.colHeaders;
              let finalisedUnits = allUnitsByName.filter(
                value => !usedUnits.includes(value)
              );
              this.units = finalisedUnits;
              this.hotSettings.data = [];
              let newData = JSON.parse(response.data[1][0].data);
              newData.forEach(el => {
                this.hotSettings.data.push(el);
              });
              this.hotSettings.columns = [];
              this.hotSettings.colHeaders.forEach((el, index) => {
                let insert = {};
                if (el === "Complete") {
                  insert.class = "complete";
                }
                if (index === 0) {
                  insert = {};
                } else {
                  insert = {
                    renderer(instance, td, row, col, prop, value) {
                      const escaped = `${value}`;

                      if (escaped === "Complete") {
                        td.style.background = "green";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "Ready") {
                        td.style.background = "yellow";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "Not Done") {
                        td.style.background = "red";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "In Progress") {
                        td.style.background = "blue";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else {
                        textRenderer.apply(this, arguments);
                      }

                      return td;
                    },
                    type: "dropdown",
                    source: [
                      "Complete",
                      "Ready",
                      "Not Done",
                      "In Progress",
                      ""
                    ],
                    width: 100
                  };
                }
                this.hotSettings.columns.push(insert);
              });
            } else {
              let today = dayjs(new Date()).format("YYYY-MM-DD");
              let first = "someUnit";
              this.hotSettings.colHeaders = [];
              this.hotSettings.colHeaders.push(today);
              this.hotSettings.colHeaders.push(first);
              this.hotSettings.data = [];
              this.hotSettings.data.push(["", ""]);
              this.hotSettings.columns = [];
              let allUnits = response.data[0].filter(el => {
                return el.unitName.length < 5;
              });
              let allUnitsByName = [];
              allUnits.forEach(el => {
                allUnitsByName.push(el.unitName);
              });
              let usedUnits = this.hotSettings.colHeaders;
              let finalisedUnits = allUnitsByName.filter(
                value => !usedUnits.includes(value)
              );
              this.units = finalisedUnits;
              this.hotSettings.colHeaders.forEach((el, index) => {
                let insert = {};
                if (el === "Complete") {
                  insert.class = "complete";
                }
                if (index === 0) {
                  insert = {};
                } else {
                  insert = {
                    renderer(instance, td, row, col, prop, value) {
                      const escaped = `${value}`;

                      if (escaped === "Complete") {
                        td.style.background = "green";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "Ready") {
                        td.style.background = "yellow";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "Not Done") {
                        td.style.background = "red";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else if (escaped === "In Progress") {
                        td.style.background = "blue";
                        td.style.color = "white";

                        textRenderer.apply(this, arguments);
                      } else {
                        textRenderer.apply(this, arguments);
                      }

                      return td;
                    },
                    type: "dropdown",
                    source: [
                      "Complete",
                      "Ready",
                      "Not Done",
                      "In Progress",
                      ""
                    ],
                    width: 100
                  };
                }
                this.hotSettings.columns.push(insert);
              });
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    removeColumn() {
      this.dialog = false;
      this.hotSettings.colHeaders.splice(1, 1);
      this.hotSettings.data.forEach(el => {
        el.splice(1, 1);
      });
      this.unitChosen = "";
      this.units = [];
      this.saveData();
      this.getTheData();
    },
    addColumn() {
      this.hotSettings.colHeaders.push(this.unitChosen);
      this.unitChosen = "";
      this.hotSettings.data.forEach(el => {
        el.push("");
      });
      let insert = {
        renderer(instance, td, row, col, prop, value) {
          const escaped = `${value}`;

          if (escaped === "Complete") {
            td.style.background = "green";
            td.style.color = "white";

            textRenderer.apply(this, arguments);
          } else if (escaped === "Ready") {
            td.style.background = "yellow";

            textRenderer.apply(this, arguments);
          } else if (escaped === "Not Done") {
            td.style.background = "red";
            td.style.color = "white";

            textRenderer.apply(this, arguments);
          } else if (escaped === "In Progress") {
            td.style.background = "blue";
            td.style.color = "white";

            textRenderer.apply(this, arguments);
          } else {
            textRenderer.apply(this, arguments);
          }

          return td;
        },
        type: "dropdown",
        source: ["Complete", "Ready", "Not Done", "In Progress", ""],
        width: 100
      };
      this.hotSettings.columns.push(insert);
      this.saveData();
      this.units = [];
      this.getTheData();
    },
    async saveData() {
      let today = dayjs(new Date()).format("YYYY-MM-DD");
      this.hotSettings.colHeaders.shift();
      this.hotSettings.colHeaders.unshift(today);
      let colHeaders = JSON.stringify(this.hotSettings.colHeaders);
      let hotdata = JSON.stringify(this.hotSettings.data);
      let data = {
        development: this.$store.state.development.id,
        dateDone: today,
        data: hotdata,
        colHeaders: colHeaders
      };
      await axios({
        method: "post",
        url: `${url}/matrixupdateWB`,
        data: data
      })
        .then(
          () => {
            this.hotSettings.data = [];
            this.getTheData();
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style src="../../node_modules/handsontable/dist/handsontable.full.css"></style>
